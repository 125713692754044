@use "../../config/" as *;

.waf-player {
  position: relative;
  margin: 2rem var(--half-space-negative);
  padding-top: 0;
  .layout-wrapper {
    max-width: var(--container-max-width);
    margin: 0 auto;
  }
  .waf {
    &-head {
      position: relative;
      margin: 0 var(--full-space-negative);
      overflow: hidden;
      height: 23rem;
      background-color: var(--light-grey-color);
      &::before,
      &::after {
        content: "";
        width: 33.5rem;
        height: 100%;
        background-image: url("/static-assets/images/cssimages/player-profile-patten1.png?v=#{$image-version}");
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        position: absolute;
        left: -7.5rem;
        top: 0;
        z-index: 2;
      }
      &::after {
        background-image: url("/static-assets/images/cssimages/player-profile-patten2.png?v=#{$image-version}");
        left: -7rem;
        z-index: 1;
      }
    }
    &-body {
      margin: auto 0;
      .tab-section {
        margin: 1.5rem auto;
      }
    }
  }
  .head-wrap {
    position: relative;
    flex-wrap: wrap;
    height: 100%;
    @include flex-config(flex, column, space-between, center);
  }
  .head-left {
    flex-basis: 100%;
    width: 100%;
    height: 100%;
  }
  .head-right {
    margin-top: 1rem;
    margin-left: 7rem;
    bottom: 2rem;
    position: absolute;
    display: none;
  }
  .filter-section {
    margin: var(--full-space) 0;
  }
  .player {
    &-information {
      height: 100%;
    }
    &-information-wrap {
      height: 100%;
      @include flex-config(flex, row, flex-start, center);
    }
    &-thumbnail {
      position: relative;
      flex-basis: 55%;
      width: 55%;
      height: 100%;
      z-index: 3;
      @include flex-config(flex, null, flex-end, null);
      img {
        width: auto;
        height: 100%;
      }
    }
    &-content {
      flex-basis: 45%;
      width: 45%;
      padding-left: 0rem;
    }
    &-name {
      margin-bottom: 0.5rem;
      .txt {
        color: var(--kabaddi-secondary-color);
        font-size: 3.2rem;
        margin-bottom: 0;
        line-height: 1;
      }
      .first-name {
        font-family: $font-regular;
      }
      .last-name {
        font-family: $font-bold;
      }
    }
    &-category {
      .player {
        &-text {
          font-size: 1.4rem;
          color: var(--kabaddi-secondary-color);
          margin-bottom: 0;
          padding-top: 1rem;
        }
      }
    }
    &-team {
      margin-top: 1rem;
      .player {
        &-team-wrap {
          @include flex-config(flex, row, flex-start, center);
        }
      }
      .country-image {
        width: 3.5rem;
        display: none;
      }
      .country-name {
        // padding-left: 0.5rem;
        .text {
          color: var(--kabaddi-secondary-color);
          margin-bottom: 0;
          font-size: 1.3rem;
          font-family: $font-regular;
        }
      }
    }
  }
  .content-section {
    padding-top: 2rem;
    .title {
      font-size: 1.4rem;
      font-family: $font-bold;
      margin-bottom: 1rem;
    }
    .content {
      font-size: var(--full-space);
      color: var(--kabaddi-secondary-color);
      margin-bottom: 0;
    }
    .highlighted-content {
      // background: linear-gradient(to left top, var(--purple-gradient-dark-color), var(--purple-gradient-light-color));
      padding-bottom: 3rem;
      margin: var(--full-space) 0;
      position: relative;
      .thumbnail {
        position: relative;
        padding: 3rem 5rem 5rem 5rem;
        &:before {
          content: "";
          background: url("/static-assets/images/cssimages/signature-vector.png?v=#{$image-version}") no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: 100%;
        }
      }
      .highlighted-title {
        color: var(--white-color);
        font-size: 1.6rem;
        font-family: $font-bold;
        .title {
          color: var(--kabaddi-secondary-color);
          position: relative;
          padding-left: var(--half-space);
          font-size: 1.6rem;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0.1rem;
            height: 100%;
            background: var(--white-color);
            background-size: 100%;
          }
        }
      }
      .content {
        padding: 0 2rem 0 2rem;
        margin-bottom: 0;
        color: var(--white-color);
        .content {
          padding: 0;
        }
      }
    }
    .left-section {
      .title {
        color: var(--background-color);
      }
    }
    .right-section {
      .title {
        color: var(--background-color);
      }
      .content {
        font-family: $font-regular;
        background: var(--light-grey-color);
        padding: var(--full-space);
        margin: 0;
        margin-bottom: var(--full-space);
      }
    }
  }
  .player-content-section {
    .head {
      .title {
        color: var(--black-color);
        font-size: 1.6rem;
        font-family: $font-bold;
        margin-bottom: 1rem;
      }
    }
    .body {
      background: var(--kabaddi-secondary-color);
      position: relative;
      padding: 3rem var(--full-space);
      z-index: 1;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url("/static-assets/images/cssimages/journey-vector-mob.png?v=#{$image-version}") no-repeat;
        background-size: 100%;
        z-index: -1;
      }
    }
    .left-content-section {
      @include flex-config(flex, row, center, center);
      position: relative;
      // margin-bottom: var(--full-space);
      .swiper {
        &.year-list-swiper {
          height: 10rem;
        }
        .swiper-button-prev,
        .swiper-button-next {
          background: none;
          position: absolute;
          left: 2rem;
          right: 2rem;
          margin: 0 auto;
          cursor: pointer;
          // @include transform(translateY(0%));
          top: 50%;
          transform: translateY(-50%);
          @include flex-config(flex, null, center, center);
          &::after {
            position: relative;
            font-family: $font-icon;
            font-size: 1.4rem;
            color: var(--white-color);
            left: auto;
            top: auto;
            @include transform(translate(0));
          }
        }
        .swiper-button-prev {
          right: auto;
          &::after {
            content: "\e829";
          }
        }
        .swiper-button-next {
          // bottom: 1rem;
          // top: auto;
          left: auto;
          &::after {
            content: "\e828";
          }
        }
        .swiper-slide-active {
          opacity: 1;
          .year-wrap {
            .name-section {
              visibility: visible;
              position: relative;
              padding-bottom: 0.5rem;
              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 0.1rem;
                bottom: 0;
                background-color: rgba(var(--kabaddi-primary-color-rgb), 0.5);
              }
            }
          }
        }
      }
    }
    .team-logo {
      width: 6.3rem;
      height: 6.3rem;
      display: inline-block;
      border-radius: 50%;
      background: var(--white-color);
      border: 0.2rem solid var(--kabaddi-primary-color);
      padding: var(--half-space);
      position: absolute;
      left: 5.5rem;
      z-index: 2;
    }
    .year-list {
      position: relative;
      padding-left: 15rem;
      height: 100%;
      transition-property: transform;
      box-sizing: content-box;
      @include flex-config(flex, row, null, null);
      .year-item {
        flex-shrink: 0;
      }
    }
    .year-wrap {
      height: 100%;
      @include flex-config(flex, column, center, flex-start);
      .name-section {
        visibility: visible;
        @include flex-config(flex, row, null, null);
      }
      .name {
        color: var(--white-color);
        font-size: 1.6rem;
        margin-bottom: 0;
        &.first-name {
          font-family: $font-bold;
        }
        &.last-name {
          padding-left: var(--half-space);
        }
      }
      .season-section {
        @include flex-config(flex, row, null, center);
      }
      .year {
        font-size: 2rem;
        font-family: $font-bold;
        color: var(--kabaddi-primary-color);
        margin-bottom: 0;
      }
      .season {
        margin-bottom: 0;
        font-size: 1.3rem;
        color: var(--white-color);
        padding-left: var(--full-space);
        position: relative;
        line-height: 1;
        &:before {
          content: "";
          position: absolute;
          left: var(--half-space);
          top: 50%;
          @include transform(translateY(-50%));
          width: 0.1rem;
          height: 100%;
          background: var(--white-color);
        }
      }
    }
    .right-content-section {
      color: var(--white-color);
      padding-top: 2rem;
      position: relative;
      // &:after{
      //     content: "";
      //     position: absolute;
      //     left: 0.3rem;
      //     right: 0;
      //     top: 2.5rem;
      //     margin: 0 auto;
      //     width: 0.8rem;
      //     height: 0.8rem;
      //     border-radius: 50%;
      //     background: var(--kabaddi-secondary-color);
      // }
      .total-matches {
        position: relative;
        z-index: 1;
        margin-bottom: 2rem;
        position: relative;
        width: 100%;
        height: 16rem;
        margin: 0 auto;
        margin-bottom: 4rem;
        @include flex-config(flex, row, space-between, center);
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          background-image: url("/static-assets/images/cssimages/journey-ground.png?v=#{$image-version}");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          z-index: -1;
        }
        .text {
          font-family: $font-medium;
          flex-basis: 50%;
          width: 50%;
          position: relative;
          p {
            margin: 0;
            font-size: 1.4rem;
            text-align: center;
            width: 50%;
            float: right;
            white-space: break-spaces;
            line-height: normal;
          }
        }
        .count {
          font-family: $font-bold;
          flex-basis: 50%;
          width: 50%;
          float: left;
          p {
            margin: 0;
            font-size: 3.8rem;
            color: var(--kabaddi-primary-color);
            width: 50%;
            float: left;
            text-align: center;
            margin-left: 1rem;
            line-height: normal;
          }
        }
      }
    }
  }
  .content {
    &-list {
      margin-top: 2rem;
      position: relative;
      // &:before{
      //     content: "";
      //     position: absolute;
      //     right: 25%;
      //     bottom: -0.3rem;
      //     width: 0.8rem;
      //     height: 0.8rem;
      //     border-radius: 50%;
      //     background: var(--kabaddi-secondary-color);
      // }
    }
    &-item {
      text-align: center;
    }
    &-label {
      font-family: $font-regular;
      color: var(--white-color);
      font-size: 1.4rem;
      margin-bottom: 0;
      line-height: normal;
    }
    &-value {
      font-size: 4rem;
      color: var(--kabaddi-primary-color);
      margin-bottom: 0;
      font-family: $font-bold;
      line-height: normal;
    }
  }
  .graph {
    &-list {
      @include flex-config(flex, null, space-around, null);
      position: relative;
      &:before {
        content: "";
        width: 100%;
        height: 0.1rem;
        background: rgba(var(--white-color-rgb), 0.2);
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1rem;
        margin: 0 auto;
      }
    }
    &-box {
      width: 10.5rem;
      height: 10.5rem;
      &::before {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
    &-label {
      font-size: 1.2rem;
      font-family: $font-regular;
      color: var(--white-color);
      margin-top: var(--full-space);
      margin-bottom: 0;
      text-align: center;
      width: auto;
    }
    &-value {
      font-family: $font-bold;
      font-size: 2.4rem;
      color: var(--kabaddi-primary-color);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  // graph-circle-svg

  .player-card-section {
    margin: 2rem 0;
    border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.2);
    .card {
      &-wrapper {
        border: 0.1rem solid var(--light-grey-color);
        border-radius: 0.3rem;
        position: relative;
        // &::after {
        //   content: "";
        //   @include orange-gradient();
        //   width: 95%;
        //   bottom: -0.3rem;
        // }
      }
      &-head {
        background-color: var(--tertiary-color);
        padding: 1rem var(--full-space);
        .title {
          font-size: 1.6rem;
          color: var(--white-color);
          font-family: $font-bold;
          margin-bottom: 0;
        }
      }
      &-body {
        padding: 0 var(--full-space);
        background-color: var(--light-grey-color);
      }
      &-item {
        margin-bottom: var(--full-space);
        position: relative;
        .circle-outer {
          stroke: var(--kabaddi-primary-color);
        }
        // &.overall {
        //   .graph-value {
        //     color: var(--kabaddi-secondary-color);
        //   }
        //   .circle-outer {
        //     stroke: var(--kabaddi-secondary-color);
        //   }
        // }
        // &.attacking {
        //   .circle-outer {
        //     stroke: var(--kabaddi-secondary-color);
        //   }
        // }
        // &.defensive {
        //   .graph-value {
        //     color: var(--green-card);
        //   }
        //   .circle-outer {
        //     stroke: var(--green-card);
        //   }
        // }
      }
    }
    .information {
      &-item {
        padding: var(--full-space) 0;
        border-bottom: 0.1rem solid rgba(var(--kabaddi-secondary-color-rgb), 0.1);
        @include flex-config(flex, row, space-between, center);
      }
      &-label {
        font-family: $font-bold;
        font-size: 1.3rem;
        color: var(--kabaddi-secondary-color);
        margin-bottom: 0;
      }
      &-count {
        font-family: $font-bold;
        font-size: 2rem;
        color: var(--kabaddi-secondary-color);
        margin-bottom: 0;
        line-height: normal;
      }
    }
    .graph-value {
      @include transform(translate(0));
    }
    .graph {
      &-item {
        padding: 2.5rem 0;
        margin: 0;
        width: 100%;
      }
      &-wrapper {
        @include flex-config(flex, row, space-between, center);
      }
      &-box {
        margin: 0 0 0 auto;
      }
      &-value {
        font-size: 2rem;
        color: var(--kabaddi-secondary-color);
        text-align: center;
        position: absolute;
        left: auto;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        flex-basis: 25%;
        width: 25%;
      }
      &-label {
        color: var(--kabaddi-secondary-color);
      }
    }
    .graph-box {
      .circle-inner {
        stroke: rgba(var(--black-color-rgb), 0.1);
        opacity: 0.4;
      }
    }
  }
  .guide-card-section {
    .card-title {
      .title {
        color: var(--background-color);
        font-size: 1.4rem;
        font-family: $font-bold;
      }
    }
    .card {
      &-wrapper {
        // border: 0.1rem solid var(--light-grey-color);
        border-radius: 0.3rem;
        overflow: hidden;
      }
      &-head {
        background-color: var(--kabaddi-secondary-color);
        padding: 1rem var(--full-space);
        display: none;
        .title {
          font-size: 1.6rem;
          color: var(--white-color);
          font-family: $font-bold;
          margin-bottom: 0;
          position: relative;
          &:after {
            content: "\e819";
            font-family: $font-icon;
            position: absolute;
            right: 0;
            top: 50%;
            @include transform(translateY(-50%) rotate(-42deg));
            color: var(--white-color);
            font-size: 1.2rem;
          }
        }
      }
      &-body {
        .stats-listing {
          background-color: var(--light-grey-color);
        }
      }
      &-item {
        margin-bottom: var(--full-space);
        .team-date {
          margin-bottom: 1rem;
          display: none;
          .date {
            font-size: 1.3rem;
            font-family: $font-bold;
            color: var(--kabaddi-secondary-color);
            margin-bottom: 0;
          }
        }
        .team {
          &-listing {
            background: var(--light-grey-color);
            height: 10rem;
            border-radius: 0.3rem;
            @include flex-config(flex, row, center, center);
            .team-a,
            .team-b {
              flex-basis: 40%;
              width: 40%;
              text-align: center;
              margin: 0 auto;
            }
            .team-b {
              .team-info {
                @include flex-config(flex, row-reverse, null, null);
              }
              .team-name {
                padding: 0 1rem 0 0;
                text-align: right;
              }
            }
            .match-update {
              .vs {
                flex-basis: 10%;
                width: 10%;
                font-size: 1.4rem;
                font-family: $font-regular;
                color: var(--kabaddi-secondary-color);
              }
            }
            .team-info {
              @include flex-config(flex, null, null, center);
            }
            .team-logo {
              width: 5rem;
              height: 5rem;
              margin-bottom: 0;
              border-radius: 50%;
              // border: 0.1rem solid rgba(var(--black-color-rgb), 0.2);
            }
            .team-name {
              font-size: 1.4rem;
              color: var(--kabaddi-secondary-color);
              font-family: $font-bold;
              padding-left: 1rem;
              text-align: left;
            }
          }
          &-score {
            background: var(--tertiary-color);
            padding: 1rem;
            border-radius: 0 0 0.3rem 0.3rem;
            @include flex-config(flex, row, space-between, center);
            .lable {
              margin-bottom: 0;
              color: var(--white-color);
              font-size: 1.3rem;
              font-family: $font-medium;
            }
            .points {
              margin-bottom: 0;
              color: var(--white-color);
              font-size: 2rem;
              font-family: $font-bold;
            }
          }
        }
        .stats-wrap {
          @include flex-config(flex, row, space-between, center);
          padding: 1rem;
          .name {
            margin-bottom: 0;
            color: var(--kabaddi-secondary-color);
            font-size: 1.3rem;
            font-family: $font-medium;
            line-height: normal;
          }
          .stats-count {
            margin-bottom: 0;
            color: var(--kabaddi-secondary-color);
            font-size: 2rem;
            font-family: $font-bold;
            line-height: normal;
          }
        }
        .stats {
          &-item {
            border-bottom: 0.1rem solid var(--light-grey-color);
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  // project-common-Code

  // tab-section

  .tab-section {
    position: relative;
    padding: 0 var(--half-space);
    width: 100%;
    margin: auto;
    transition: 500ms ease-in-out;
    transition-property: bottom;
  }

  .primary-tab {
    background-color: var(--white-color);
    border-bottom: 0.2rem solid var(--light-grey-color);
    width: 100%;
    list-style: none;
    height: 5rem;
    border-radius: 0.3rem;
    padding: 0.2rem;
    @include flex-config(flex, null, null, center);
  }

  .tab-name {
    position: relative;
    width: 50%;
    height: 100%;
    text-align: center;
    position: relative;
    cursor: pointer;
    @include flex-config(flex, null, center, center);
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 2rem;
      width: 0.1rem;
      background-color: var(--light-grey-color);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &.active,
    &:hover,
    &:focus {
      &::before {
        content: "";
        width: 100%;
        height: 0.4rem;
        background: var(--kabaddi-accent-color);
        position: absolute;
        bottom: -0.2rem;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      span {
        color: var(--kabaddi-accent-color);
      }
    }
    &.active {
      cursor: default;
    }
    span {
      color: var(--kabaddi-secondary-color);
      font-size: 1.4rem;
      font-family: $font-bold;
    }
  }

  .social-share {
    display: none;
  }

  // graph-circle-svg
  .graph-box {
    position: relative;
    margin: 0 auto;
    @include flex-config(flex, null, center, center);
    &::before {
      content: "";
      background-color: rgba(var(--white-color-rgb), 0.5);
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    svg {
      width: 100%;
      height: 100%;
      overflow: visible;
      filter: drop-shadow(0 0.3rem 0.1rem rgba(var(--black-color-rgb), 0.2));
    }
    .circle-inner {
      stroke-width: 3;
      stroke: rgba(var(--white-color-rgb), 0.5);
    }
    .circle-outer {
      animation: circle-chart-fill 2s reverse;
      stroke-width: 3;
      transform: rotate(-90deg);
      transform-origin: center;
      stroke-linecap: round;
      stroke: var(--kabaddi-primary-color);
    }
    [stroke-dasharray="0,100"] {
      stroke-linecap: unset;
    }
  }

  // Animation-code
  @-moz-keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }

  @-o-keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }

  @-webkit-keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }

  @keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .waf-player {
    .waf {
      &-head {
        width: 100%;
        height: 28rem;
        overflow: hidden;
        margin: 0;
        background-image: url("/static-assets/images/cssimages/player-profile-bg.png?v=#{$image-version}");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        &::before,
        &::after {
          left: 0;
        }
      }
    }
    .head-wrap {
      @include flex-config(flex, row, space-between, center);
    }
    .head-left {
      flex-basis: 80%;
      width: 80%;
    }
    .head-right {
      flex-basis: 18%;
      width: 18%;
      margin: 0;
      position: relative;
      bottom: 0;
    }
    .player {
      &-information-wrap {
        @include flex-config(flex, row, flex-start, center);
      }
      &-thumbnail {
        flex-basis: 35%;
        width: 35%;
      }
      &-content {
        flex-basis: 65%;
        width: 65%;
        padding-left: 0;
      }
      &-name {
        @include flex-config(flex, row, flex-start, center);
        .text {
          font-size: 2.8rem;
        }
        .last-name {
          padding-left: 0.5rem;
        }
      }
      // &-category {
      //   .player {
      //     &-text {
      //       font-size: 2rem;
      //     }
      //   }
      // }
      &-team {
        .country-image {
          width: 4.5rem;
        }
        .country-name {
          .text {
            font-size: var(--full-space);
          }
        }
      }
    }
    .content-section {
      @include flex-config(flex, row, null, null);
      .content {
        font-size: 1.6rem;
      }
      .left-section {
        flex-basis: 75%;
        width: 75%;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: 2rem;
          left: 0;
          width: 100%;
          height: 0.1rem;
          background: var(--light-grey-color);
        }
        > .content {
          padding-bottom: 2rem;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1rem;
            background: var(--light-grey-color);
          }
        }
      }
      .right-section {
        flex-basis: 25%;
        width: 25%;
        padding-left: var(--full-space);
      }
      .highlighted-content {
        @include flex-config(flex, row, flex-start, center);
        padding: 0;
        border: 0.1rem solid var(--dark-grey-color);
        border-radius: 0.3rem;
        .thumbnail {
          padding: 4rem;
          width: 50%;
          flex-basis: 50%;
          text-align: center;
        }
        .highlighted-title {
          margin-bottom: var(--full-space);
        }
        .content {
          padding: 0 2rem;
          .content {
            padding: 0;
          }
        }
      }
    }
    .player-content-section {
      .body {
        @include flex-config(flex, row, null, center);
        padding: 5rem var(--full-space);
        max-height: 480px;
        &:before {
          background: url("/static-assets/images/cssimages/journey-vector.png?v=#{$image-version}") no-repeat;
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: -12rem;
          right: 0;
          margin: 0 auto;
          background: url("/static-assets/images/cssimages/journey-pointers.png?v=#{$image-version}") no-repeat;
          background-position: center;
          background-size: contain;
          width: 25rem;
          height: 30rem;
          transform: translateY(-50%);
        }
      }
      .team-logo {
        width: 8.3rem;
        height: 8.3rem;
        position: static;
      }
      .year {
        &-wrap {
          @include flex-config(null, null, null, center);
          .name {
            font-size: 1.8rem;
          }
          .year {
            font-size: 2.8rem;
          }
          .season {
            font-size: 1.4rem;
          }
          .name-section {
            visibility: hidden;
          }
        }
        &-list {
          padding-left: 1rem;
          @include flex-config(flex, column, null, null);
        }
        &-item {
          opacity: 0.1;
          @include flex-config(flex, null, null, center);
        }
      }
      .left-content-section {
        flex-basis: 40%;
        width: 40%;
        .swiper {
          &.year-list-swiper {
            height: 48rem;
          }
          .swiper-button-prev,
          .swiper-button-next {
            left: 2rem;
            right: 2rem;
            @include transform(translateY(0%));
          }
          .swiper-button-prev {
            right: -6rem;
            top: 2rem;
            &::after {
              content: "\e82a";
            }
          }
          .swiper-button-next {
            top: auto;
            bottom: 2rem;
            right: -6rem;
            &::after {
              content: "\e827";
            }
          }
        }
      }
      .right-content-section {
        flex-basis: 60%;
        width: 60%;
        padding-top: 0;
        position: relative;
        &:before {
          display: none;
        }
        .total-matches {
          margin-bottom: 2rem;
          width: 28rem;
          &:before {
            background-image: url("/static-assets/images/cssimages/journey-ground-mob.png?v=#{$image-version}");
          }
          .text {
            p {
              font-size: 1.3rem;
              margin-right: 1rem;
            }
          }
          .count {
            p {
              font-size: 3.4rem;
              margin-left: var(--full-space);
            }
          }
        }
      }
    }
    .graph {
      &-list {
        @include flex-config(flex, row, center, null);
        &:before {
          width: 30rem;
          bottom: -2rem;
        }
      }
      &-item {
        margin: 0 2rem;
      }
    }
    .content {
      &-list {
        margin-top: 4rem;
      }
    }
    .player-card-section {
      .card {
        &-list {
          display: flex;
          margin: 0 -0.8rem;
          flex-wrap: wrap;
        }
        &-item {
          width: 50%;
          flex-basis: 50%;
          padding: 0 0.8rem;
          flex-shrink: 0;
        }
      }
    }
    .guide-card-section {
      .card {
        &-list {
          @include flex-config(flex, row, center, null);
          margin: 0 -0.3rem;
        }
        &-item {
          flex-basis: 33.33%;
          width: 33.33%;
          padding: 0 0.3rem;
        }
      }
    }
    .tab-section {
      width: 55%;
    }
  }
}

@media screen and (min-width: $desktop-min-width) {
  .waf-player {
    .player-card-section {
      .card {
        &-item {
          width: 33.33%;
          flex-basis: 33.33%;
          padding: 0 0.3rem;
        }
      }
    }
  }
  // project-common-Code

  // tab-section
  .tab-section {
    width: 70rem;
  }
}
